<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div class="aside-primary d-flex flex-column align-items-center flex-row-auto">
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
        class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
        style="height: 528px; overflow: hidden;"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column" role="tablist">
          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Overview'"
			 v-bind:class="asideMenuClass"
          >
            <a
              href="/#/overview"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="0"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Live'"
          >
            <a
              href="/#/live"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="1"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Map/Compass.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
		  <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Rapport'"
          >
            <a
              href="/#/reporting"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="1"
            >
              <span class="svg-icon svg-icon-xl">

                <inline-svg src="media/svg/icons/Media/Equalizer.svg" />

              </span>
            </a>
          </li>
		  <li 
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Trajet'"
          >
            <a
              href="/#/trajet"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="1"
            >
              <span class="svg-icon svg-icon-xl">

                <inline-svg src="media/svg/icons/Communication/Group.svg" />

              </span>
            </a>
          </li>
		  <li 
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="' Gestion de carburant'"
          >
            <a
              href="/#/fuel"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="1"
            >
              <span class="svg-icon svg-icon-xl">

                <inline-svg src="media/svg/icons/Electric/Gas-stove.svg" />

              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--end::Item-->
        </ul>

       <!-- <ul class="nav flex-column" role="tablist">
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Latest Projects'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="0"
            >
              <span class="svg-icon svg-icon-xl">
                <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
              </span>
            </a>
          </li>
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Metronic Features'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="1"
            >
              <span class="svg-icon svg-icon-xl">

                <inline-svg src="media/svg/icons/Communication/Group.svg" />

              </span>
            </a>
          </li>
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Latest Reports'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="2"
            >
              <span class="svg-icon svg-icon-xl">
                <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
              </span>
            </a>
          </li>
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Project Management'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              role="tab"
              v-on:click="setActiveTab"
              data-tab="3"
            >
              <span class="svg-icon svg-icon-xl">
                <inline-svg src="media/svg/icons/General/Shield-check.svg" />
              </span>
            </a>
          </li>
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'User Management'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg active"
              role="tab"
              v-on:click="setActiveTab"
              data-tab="4"
            >
              <span class="svg-icon svg-icon-xl">
                <inline-svg src="media/svg/icons/Home/Library.svg" />
              </span>
            </a>
          </li>
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Finance &amp; Accounting'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              role="tab"
              v-on:click="setActiveTab"
              data-tab="5"
            >
              <span class="svg-icon svg-icon-xl">
                <inline-svg src="media/svg/icons/Files/File-plus.svg" />
              </span>
            </a>
          </li>
        </ul>-->
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->
      <!--begin::Footer-->
      <div
        class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10"
      >
        <!--begin::Aside Toggle-->
        <!--<span
          class="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
          id="kt_aside_toggle"
          ref="kt_aside_toggle"
          data-placement="right"
          data-container="body"
          data-boundary="window"
          v-b-tooltip.hover.right="'Toggle Aside'"
        >
          <i class="ki ki-bold-arrow-back icon-sm"></i>
        </span>-->
        <!--end::Aside Toggle-->
        <!--begin::Quick Actions-->
         <KTQuickActions></KTQuickActions>
        <!--end::Quick Actions-->
        <!--begin::Quick Panel-->
        <!--<KTQuickPanel></KTQuickPanel>-->
        <!--end::Quick Panel-->
        <!--begin::User-->
        <KTQuickUser></KTQuickUser>
        <!--end::User-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
    <!--begin::Secondary-->

  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";
//import KTMenu from "@/view/layout/aside/Menu.vue";
import KTQuickActions from "@/view/layout/extras/offcanvas/QuickActions.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
//import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 0
    };
  },
  components: {
    KTBrand,
    //KTMenu,
    KTQuickActions,
    KTQuickUser,
   // KTQuickPanel
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });
  },
  methods: {
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
